import Head from "next/head";
import { NextRouter, useRouter } from "next/router";

export const getServerSideCurrentUrl = (
  router: NextRouter,
  basePath: string
) => {
  const path = router.asPath;
  const fullUrl = new URL(path, basePath);
  const urlWithNoParams = new URL(fullUrl.pathname, fullUrl.origin);
  return urlWithNoParams.toString();
};

export const deriveDataFromContentfulEntry = (entry: any) => {
  return {
    title: entry.title,
    description: entry.excerpt,
    image: entry.shareImage?.url
  };
};

const SEO: React.FC<{
  description?: string;
  title?: string;
  image?: string;
}> = ({
  description,
  title,
  image = "https://www.coinage.media/social.png"
}) => {
  const content = {
    coinage: {
      title: "Coinage | Bitcoin, Ethereum, Crypto News",
      image,
      twitter: "@coinage_media",
      defaultDescription:
        "Bitcoin, Ethereum, crypto, blockchain, DeFi, and web3 news with a little personality and skepticism from our community."
    }
  };

  const ogImage = content.coinage.image;
  const siteDescription = description ?? content.coinage.defaultDescription;
  const siteTitle = title ?? content.coinage.title;
  const router = useRouter();

  return (
    <Head>
      <title>{siteTitle}</title>
      <link rel="icon" href="/favicon.png" />
      <meta name="description" content={siteDescription} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={siteTitle} />
      <meta property="og:description" content={siteDescription} />
      <meta property="og:site_name" content={"Coinage Media"} />
      <meta property="og:image" content={ogImage} />
      <meta property="twitter:card" content="summary_large_image" />
      <meta
        property="twitter:url"
        content={getServerSideCurrentUrl(
          router,
          "https://www.coinage.media"
        )}></meta>
      <meta
        property="og:url"
        content={getServerSideCurrentUrl(
          router,
          "https://www.coinage.media"
        )}></meta>
      <meta property="twitter:creator" content={content.coinage.twitter} />
      <meta property="twitter:title" content={siteTitle} />
      <meta property="twitter:description" content={siteDescription} />
      <meta property="twitter:image" content={ogImage} />
      <meta
        name="facebook-domain-verification"
        content="p65gcryxumfgii98cuq6u6gjp8lovi"
      />
      <link
        rel="canonical"
        href={getServerSideCurrentUrl(router, "https://www.coinage.media")}
      />
    </Head>
  );
};

export default SEO;
